<template>
    <div id="app">
    </div>
</template>


<style scoped>
</style>

/*
* 跳至测试环境的url
*/

<script>
import {appProd} from '@/config/env.dev'
    export default {
        data() {
            return {};
        },
        mounted() {
            var query = this.$route.query;
            var code = query.code;
            // if (appProd === 'dev') {
              window.location.href = 'http://testxuexiao.fsytss.com?code=' + code + '&state=' + query.state;
            // } else {
            //   window.location.href = 'http://test-mobile.zhtc.zhongshanyun.cn?code=' + code + '&state=' + query.state;
            // }
        },
        methods: {},
        computed: {}
    };
</script>
